.parent {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  height: 320px;
  width: 270px;
  text-align: center;
  align-items: center;
}
.offer_text {
  font-family: "Josefin Sans";
  font-size: 22px;
  line-height: 26px;
  color: #151875;
}
.offer_desc{
    color: #1A0B5B4D;
}
