.profile-info-main{
    background-color: #eeeffb;
    border-radius: 10px;
    margin-bottom: 1rem;
    padding-bottom: 2rem;
}

.profile-info-title{
    background-color: #f9f9f9;
    border-radius: 10px;
    color: #373d57;
    font-size: 24px;
    font-weight: 700;
    padding: 3% 5%;
    margin-top: 0px !important;

}

.info{
    margin-left: 2rem;
}   