.grid_bottom {
  justify-content: start;
  background-color: red($color: #000000);
  margin-top: 10px !important;
}

@media screen and (max-width: 1120px) {
  .grid_bottom {
    justify-content: center;
  }
}
@media screen and (max-width: 830px) {
  .grid_bottom {
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
  }
}
