.input-style {
  height: 3rem;
  width: 3rem !important;
}
.dialog-for-otp {
//   margin-top: 10rem;
//   height: 20rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
}

.otp-dialog-title {
  background-color: #eeeffb;
  font-size: 1.5rem;
  padding: 2rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
}
.dialog-content-box {
  max-height: fit-content !important;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.set-new-password {
  font-size: 2rem;
  font-weight: 700;
  margin-left: 5%;
  text-align: center;
  margin-bottom: 5px !important;
}
.set-password-info {
  color: grey;
  font-size: 14px;
  padding: 0 20%;
  text-align: center;
  margin-bottom: 2rem;
}
.dialog-content-img{
    height: 15rem;
    margin-top: 1rem;
}
.input-field{
    width: 22.5rem !important;
    margin-top: 15px !important;
}
.container-style{
    margin-bottom: 1.5rem;
}
.proceed{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    background-color: #FB2E86 !important;
    width: 10rem !important;
}

.proceed:hover{
    background-color: #FB2E86 !important;
}