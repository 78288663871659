.top {
  text-align: center;
  box-sizing: border-box;
}
.profileImage {
  border-radius: 1rem;
  margin-top: 2rem;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 50%;
  height: 20rem;
  max-width: 20rem;

}

.profile-box {
  background-color: #eeeffb;
  border-radius: 1rem;
  padding-bottom: 3.5rem;
}

.account {
  background-color: #f9f9f9;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .account-p-1{
    color: #373d57;
    font-size: 24px;
    font-weight: 700;
    margin: 0px !important;
  }
  .account-p-2{
    text-align: right;
    vertical-align: center;
    margin-right: 10px;
    text-decoration: line line-through;
    background-color: #F42E86 !important;
    color: white;
  }
}

.icon {
  vertical-align: center;
}

.change-password {
  display: flex;
  align-items: center;
  vertical-align: middle;
  gap: 10px;
  padding: 0px 15px;
  margin-top: 1.5rem;
  cursor: pointer;
}

.logout {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
}
.logout:hover{
  cursor: pointer;
}

//modal
.profile-edit-modal-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #EEEFFB;
}
.profile-edit-cross-icon{
  color: red !important;
  margin-right: 5px;
}

.profile-edit-cross-icon:hover{
  cursor: pointer;
}
.modal-profile-image{
  border-radius: 1rem;
  margin-top: 2rem;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 50%;
  height: 12rem;
  width: 12rem;
}

.profile-modal-box{
  background-color: wheat;
}
.profile-modal-image-section{
  display: flex !important;
  flex-direction: column !important;
  gap: 1rem;
  align-items: center;
}

.profile-edit-modal-container{
  padding: 2rem;
}

.btn-update{
  margin: 0px 10px 10px 10px !important;
  background: #FB2E86 !important;
  border-radius: 2px;
}
.btn-cancel{
  margin: 0px 10px 10px 10px !important;
  background: #FB2E86 !important;
  border-radius: 2px;
}

.btn-change{
  background: #FB2E86 !important;
  border-radius: 2px;
}
// .profile-dialog-modal{
//   max-width: 1200rem !important;
//   max-height: fit-content;
// }
.changeProfilePicture{
  background-color: #FB2E86 !important;
  padding: 0.6rem 1.5rem;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}