.otp-dialog-title{
    background-color: #EEEFFB;
    color: red($color: #000000);
    font-size: 1.5rem;
    padding: 2rem;
}

.dialog-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem;
}

.reset-pass-img{
    padding: 1rem 0.5rem 1rem 0.5rem !important;
    width: 27rem;
}

.text-reset-password{
    font-size: 2rem;
    font-weight: 600;
    margin-top: 1rem;
}
.text-enter-your-email{
    margin-bottom: 1rem;
    margin-top: 0;
}

.proceed{
    margin-top: 2rem !important;
    background-color: #FB2E86 !important;
}

.proceed:hover{
    background-color: #FB2E86 !important;
}