.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  position: relative;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f7fb;
    flex-direction: column;
    position: relative;
    height: 250px;
    width: 270px;
    margin-left: 0.4rem;
    img{
      max-width: 11.12rem;
      max-width: 11.12rem;
    }
    .view-details {
      position: absolute;
      bottom: 1rem;
      background-color: #08d15f;
      border-radius: 2px;
      font-family: "Josefin Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: #ffffff;
    }
  }
  .bottom {
    background-color: #ffffff;
    height: 125px;

    .a {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #fb2e86;
    }
    .b {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      .b1 {
        background-color: #05e6b7;
        border-radius: 10px;
        width: 14px;
        height: 4px;
      }
      .b2 {
        @extend .b1;
        background-color: #f701a8;
      }
      .b3 {
        @extend .b2;
        background-color: #00009d;
      }
    }
    .c {
      font-family: "Josefin Sans";
      font-size: 14px;
      line-height: 16px;

      /* Text */

      color: #151875;
    }
    .d {
      @extend .c;
      margin-bottom: 2px;
    }
  }
.icons {
  text-align: left !important;
  // padding: 0 0.4rem;
  // margin-left: 5px;
  display: flex;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  gap: 0.7rem;
  .icon {
    margin-top: 10px;
    color: #2f1ac4;
  }
  .icon:hover{
    cursor: pointer;
  }
}
