.main-container {
  display: flex !important; 
  gap: 10px;
  padding: 5px 0px 5px 10px !important;
  align-items: center;
}

.product-name {
  font-family: "Josefin Sans";
  font-size: 14px !important;
  line-height: 16px;

  color: #000000;
}

.product-common {
  font-family: "Josefin Sans";
  font-size: 14px !important;
  line-height: 16px;

  color: #a1a8c1;
}

.image-box{
  position: relative;
  img{
    max-width: 4rem;
    max-height: 4rem;
  }
}
// .cross-icon{
//   position:absolute;
//   top: 0;
//   right: 0;
// }