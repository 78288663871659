.featured_parent{
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}
// .carousell{
//     display: flex;
//     flex-direction: column;
//     flex-wrap: wrap;
// }
.slick-prev::before{
    color: black !important;
}
.slick-next::before{
    color: black !important;
}