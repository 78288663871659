.parent {
    display: flex;
    align-items: center !important;
    justify-content: center;
    margin: 5rem 0rem 5rem 0rem;
  }
  
  .parent-stack {
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0px 0px 25px 10px #f8f8f8;
    padding: 20px;
    justify-content: center;
  }
  .btn-signin,
  .btn-signin:hover,
  .btn-signin:active {
    background-color: #fb2e86 !important;
    border-radius: 3px;
    height: 47px;
    padding: 0px !important;
    color: white !important;
    margin-top: 10px !important;
  }
  .login {
    font-family: "Josefin Sans";
    font-size: 32px !Important;
    line-height: 38px;
    color: #000000;
  }
  
  .login-text-common {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
  
    color: #9096b2;
  }
  
  .textfield-box{
      margin-top: 2rem;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 50ch;
  }

  .input-style {
    height: 3rem;
    width: 3rem !important;
  }

  .container-style{
    margin-bottom: 1.5rem;
}

.otp-dialog-title {
  background-color: #eeeffb;
  font-size: 1.5rem;
  padding: 1rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem !important;
}
  
.dialog-box{
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}

.verify-btn{
  background-color: #fb2e86 !important;
  margin-bottom: 1rem !important;

}
.otp-img{
  height: 10rem;
  width: auto;
  margin-bottom: 1rem;
}
.activate-your-account{
  font-size: 2rem;
  font-weight: 700;
  margin-left: 5%;
  text-align: center;
}
  // @media screen and (max-width: 414px) {
  //     .parent{
  //         margin: 1rem;
  //     }
  // .parent-stack {
  //     .textfield-box{
  //         // & .MuiTextField-root{
  //         //     width: 25ch !important;
  //         // }
  //         .TextField{
  //             width: 25ch !important;
  //         }
  //     }
  // }
  // }