.box-parent {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  flex-wrap: wrap;
  min-height: 10rem;
}
.box-left {
  .text-1 {
    font-family: "Josefin Sans";
    font-size: 22px !important;
    line-height: 26px;
    color: #151875;
  }
  .text-2 {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #8a8fb9;
  }
}

.box-right {
  display: flex;
  gap: 2rem;
  .list-view:hover {
    cursor: pointer;
  }
  .grid-view:hover {
    cursor: pointer;
  }
  .sort_by {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 30px !important;
    line-height: 18px;
    color: #8a8fb9;
  }
}
