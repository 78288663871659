.parent {
    display: flex;
    align-items: center !important;
    justify-content: center;
    margin: 5rem 0rem 5rem 0rem;
  }
  
  .parent-stack {
    align-items: center;
    background-color: #ffffff;
    padding: 20px;
    justify-content: center;
  }
  .btn-signin,
  .btn-signin:hover,
  .btn-signin:active {
    background-color: #fb2e86 !important;
    border-radius: 3px;
    height: 47px;
    padding: 0px !important;
    color: white !important;
    margin-top: 10px !important;
  }
  .login {
    font-family: "Josefin Sans" !important;
    font-size: 32px !Important;
    line-height: 38px;
    color: #000000;
  }
  
  .login-text-common {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
  
    color: #9096b2;
  }
  
  .textfield-box{
      margin-top: 2rem;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 50ch;
  }
  
  // @media screen and (max-width: 414px) {
  //     .parent{
  //         margin: 1rem;
  //     }
  // .parent-stack {
  //     .textfield-box{
  //         // & .MuiTextField-root{
  //         //     width: 25ch !important;
  //         // }
  //         .TextField{
  //             width: 25ch !important;
  //         }
  //     }
  // }
  // }
  .top {
    background: #ffffff;
    background: #ffffff;
    border-radius: 27px;
    padding: 20px 30px;
    margin-top: 2rem !important;
    max-width: 37rem !important;
    position: relative;
    max-height: 36rem;
  }
  .close-btn{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 2.5rem !important;
  }
  .close-btn:hover{
    cursor: pointer;
  }