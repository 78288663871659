.text-1 {

  font-family: 'Josefin Sans';
  line-height: 42px;
  font-weight: 700;
  font-size: 2.5rem !important;
  color: #101750;
}

.text-2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #000000;
}
.text-3{
  @extend .text-2;
  color: #FB2E86;
}
