.product-name {
  font-family: "Josefin Sans";
  font-size: 16px !important;
  color: #151875;
  display: flex;
  align-items: center;
  gap: 50px;

}

.product-price {
  font-family: "Josefin Sans";
  font-size: 13px !important;
  color: #151875;
}

.star-icon{
}