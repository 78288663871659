.top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15rem;
  width: 15rem;
  border-radius: 50%;
  background-color: #f6f7fb;
  img{
    width: auto;
  }
}
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main:hover{
  cursor: pointer;
}
.mid {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;


  color: #151875;
}
.bottom {
  font-family: "Josefin Sans";
  font-size: 16px;
  line-height: 19px;
  color: #151875;
}
.cat-img{
  max-width: 9.8rem;
  max-width: 9.8rem;
}