.parent {
  display: flex;
  .left {
    display: flex;
    flex-direction: row;
    .bulb {
      width: 30%;
      display: flex;
      flex-direction: row;
    }
    .info {
      .shop_now_btn {
        background-color: #fb2e86;
        width: 10.1rem;
        height: 3rem;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 39rem;
      .top_info {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #fb2e86;
      }
      .mid_info {
        font-family: "Josefin Sans";
        font-size: 53px;
        color: #000000;
      }
      .bottom_info {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #8a8fb9;
      }
    }
  }
  .right {
    display: flex;
    // align-items: center;
    // justify-content: flex-start;
    position: relative;
    .backgroundImage {
      height: 40.5rem;
    }
    .chair {
      position: absolute;
      top: 3rem;
      max-height: 31.75rem;
      border-radius: 10%;
      min-height: 30rem;
      max-width: 550px;
    }
    // .discountBoard {
    //   position: absolute;
    //   top: 3rem;
    //   right: 9rem;
    // }
    .discount {
      position: absolute;
      width: 10rem;
      height: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #00C1FE;
      top: 5rem;
      right: 5rem;
      font-family: "Josefin Sans";
      font-size: 35px;
      line-height: 44px;
      color: white;
      text-align: center;
      vertical-align: middle;
    }
  }

  .bulb_img {
    height: 387px;
    width: 387px;
  }
}

@media screen and (max-width: 1600px) {
  .parent {
    flex-wrap: wrap;
    justify-content: space-around;
    .left {
      gap: 0;
      .info {
        .shop_now_btn {
          background-color: #fb2e86;
          width: 7.1rem;
          height: 2rem;
          font-size: 0.9rem;
        }
        .top_info {
          font-size: 2vw;
        }
        .mid_info {
          font-size: 2vw;
        }
        .bottom_info {
          font-size: 2vw;
        }
      }
    }
    .right {
      align-items: center;
      justify-content: space-around;
      .backgroundImage {
        height: auto;
        width: 100%;
      }
      .chair {
        height: auto;
        width: 100%;
      }
      .discountBoard {
        position: absolute;
        margin: 0 0 17rem 20rem;
        height: 6rem;
      }
      .discount {
        left: 33.3rem;
        top: 34rem;
        font-family: "Josefin Sans";
        font-size: 23px;
        line-height: 44px;
        margin: -16rem -2.5rem;
      }
    }
    .right:hover, .right:active, .right:focus{
      cursor: pointer !important;
    }
    .bulb_img {
      height: 250px;
      width: auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .parent {
    flex-wrap: wrap;
    justify-content: space-around;
    .left {
      gap: 0;
      .info {
        .shop_now_btn {
          background-color: #fb2e86;
          width: 7.1rem;
          height: 2rem;
          font-size: 0.9rem;
        }
        .top_info {
          font-size: 2vw;
        }
        .mid_info {
          font-size: 2vw;
        }
        .bottom_info {
          font-size: 2vw;
        }
      }
    }
    .right {
      align-items: center;
      justify-content: space-around;
      .backgroundImage {
        height: 25rem;
        width: auto;
      }
      .chair {
        height: 22rem;
        width: auto;
      }
      .discountBoard {
        position: absolute;
        margin: 0 0 17rem 20rem;
        height: 6rem;
      }
      .discount {
        height: auto;
        width: auto;
      }
    }
    .bulb_img {
      height: 250px;
      width: auto;
    }
  }
}
